import ApiRoutes from './ApiRoutes'
import HTTP from './HTTPService'

export default {
  getSortedCouriers(data) {
    return HTTP.get(ApiRoutes.sortedCouriersURL(), data)
  },
  getCouriers(types) {
    return HTTP.get(ApiRoutes.filteredUsersURL(), { types })
  },
  createCourier(courier) {
    return HTTP.post(ApiRoutes.usersURL(), courier)
  },
  updateCourier(userId, courier) {
    return HTTP.patch(ApiRoutes.userURL(userId), courier)
  },
  toggleCourierActive(userId, isActive) {
    return HTTP.patch(ApiRoutes.toggleActiveUserURL(userId), { isActive })
  }
}
