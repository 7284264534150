import TimeslotApi from '@/store/transport/TimeslotApi'

export default {
  resetTimeslotStore({ commit }) {
    commit('resetTimeslotStore')
  },
  getTimeslotsRequest({ dispatch }, data) {
    TimeslotApi.getTimeslots(data).then(
      (response) => {
        dispatch('getTimeslotsSuccess', response)
      },
      (reason) => {
        dispatch('getTimeslotsFailed', reason)
      }
    )
  },
  getTimeslotsSuccess({ commit }, response) {
    commit('setTimeslots', response.timeslots)
  },
  getTimeslotsFailed() {}
}
