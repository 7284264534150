export const ONE_MINUTE = 60
export const ONE_HOUR = ONE_MINUTE * 60
export const INTERVAL = ONE_MINUTE * 15

export const LEAVE_BY_OPTIONS = [
  60,
  45,
  30,
  15,
  0
]
export const TRIP_STATES = {
  ASSIGNED: 'assigned',
  PACKED: 'packed',
  STARTED: 'started',
  COMPLETED: 'completed',
  FAILED: 'failed'
}
export const TASK_STATES = {
  INCOMING: 'incoming',
  ASSIGNED: 'assigned',
  PACKED: 'packed',
  ON_COURIER: 'on_courier',
  IN_DELIVERY: 'in_delivery',
  READY_FOR_PICKUP: 'ready_for_pickup',
  COMPLETED: 'completed',
  FAILED: 'failed'
}
export const USER_ROLES = {
  CUSTOMER_SPECIALIST: 'CustomerSpecialist',
  COURIER: 'Courier'
}
