import Vue from 'vue'
import VueRouter from 'vue-router'
import cookies from '@js/cookies'
import store from './store'

Vue.use(VueRouter)

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.

const HomePage = () => import(/* webpackChunkName: "home" */ './views/HomePage.vue')
const LoginPage = () => import(/* webpackChunkName: "login" */ './views/LoginPage.vue')
const AssignmentPage = () => import(/* webpackChunkName: "assignment" */ './views/AssignmentPage.vue')
const PrintPage = () => import(/* webpackChunkName: "print" */ './views/PrintPage.vue')
const PackAndDispatchPage = () => import(/* webpackChunkName: "packAndDispatch" */ './views/PackAndDispatchPage.vue')
const TasksPage = () => import(/* webpackChunkName: "tasks" */ './views/TasksPage.vue')
const UsersPage = () => import(/* webpackChunkName: "users" */ './views/UsersPage.vue')
const CouriersPage = () => import(/* webpackChunkName: "couriers" */ './views/CouriersPage.vue')

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      beforeEnter: beforeEnterLoginPage
    },
    {
      path: '/map',
      redirect: '/assignment'
    },
    {
      path: '/assignment',
      name: 'assignment',
      component: AssignmentPage
    },
    {
      path: '/print',
      name: 'print',
      component: PrintPage
    },
    {
      path: '/pack',
      redirect: '/pack-and-dispatch'
    },
    {
      path: '/dispatch',
      redirect: '/pack-and-dispatch'
    },
    {
      path: '/pack-and-dispatch',
      name: 'pack-and-dispatch',
      component: PackAndDispatchPage
    },
    {
      path: '/orders/:id?',
      name: 'tasks',
      component: TasksPage,
      props: true
    },
    {
      path: '/users',
      name: 'users',
      component: UsersPage
    },
    {
      path: '/couriers',
      name: 'couriers',
      component: CouriersPage
    }
  ]
})

router.beforeEach((to, _from, next) => {
  const hasExternalAccessToken = !!cookies.getCookie('external_access_token')
  const requiresLogin = to.name !== 'login'

  if (!store.getters['sessionUser/isLoggedIn']) {
    store.dispatch('sessionUser/checkAuthRequest')
  }
  if (requiresLogin && !hasExternalAccessToken) {
    return redirectToLogin(to, next)
  }
  next()
})

let redirectedToLogin = false

function beforeEnterLoginPage(_to, from, next) {
  if (redirectedToLogin) {
    redirectedToLogin = false
  } else if (from.name !== 'login') {
    storeRedirectPath(from.path)
  }
  next()
}

function redirectToLogin(to, next) {
  storeRedirectPath(to.path)
  redirectedToLogin = true
  return next({ name: 'login' })
}

function storeRedirectPath(path) {
  // dont store if is reset password path
  if (path.includes('/reset-password/')) return

  const key = `${window.location.host}/redirectPath`
  try {
    window.sessionStorage.setItem(key, path)
  } catch(_) {
    cookies.setCookie(key, path)
  }
  cookies.deleteCookie(key)
}

export default router
