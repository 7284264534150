import Buefy, {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  Datepicker,
  Dialog,
  Dropdown,
  Field,
  Icon,
  Input,
  Modal,
  Slider,
  Switch,
  Tag,
  Tooltip
} from 'buefy'
import Vue from 'vue'

Vue.use(Buefy, {
  defaultIconPack: 'fas'
})

Vue.use(Autocomplete)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(Collapse)
Vue.use(Datepicker)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Modal)
Vue.use(Slider)
Vue.use(Switch)
Vue.use(Tag)
Vue.use(Tooltip)
