import ApiRoutes from './ApiRoutes'
import HTTP from './HTTPService'

export default {
  getTrips(data) {
    return HTTP.get(ApiRoutes.tripsURL(), data)
  },
  assignTrips(data) {
    return HTTP.post(ApiRoutes.assignTripsURL(), data)
  },
  updateTrips(data) {
    return HTTP.post(ApiRoutes.updateTripsURL(), data)
  },
  getTrip(tripId) {
    return HTTP.get(ApiRoutes.tripURL(tripId))
  },
  removeTrip(tripId) {
    return HTTP.delete(ApiRoutes.tripURL(tripId))
  },
  reassignTrip(tripId, specialistId) {
    return HTTP.patch(ApiRoutes.reassignTripURL(tripId), { specialistId })
  },
  updateTripLeaveBy(tripId, leaveBy) {
    return HTTP.patch(ApiRoutes.updateTripLeaveByURL(tripId), { leaveBy })
  },
  updateTripPackerName(tripId, packerName) {
    return HTTP.patch(ApiRoutes.updateTripPackerNameURL(tripId), { packerName })
  },
  updateTripPacked(tripId, pack) {
    return HTTP.patch(ApiRoutes.updateTripPackedURL(tripId), { pack })
  },
  updateTripStarted(tripId, start) {
    return HTTP.patch(ApiRoutes.updateTripStartedURL(tripId), { start })
  },
  updateTripNotes(tripId, notes) {
    return HTTP.patch(ApiRoutes.updateTripNotesURL(tripId), { notes })
  },
  markTripAsCompleted(tripId) {
    return HTTP.patch(ApiRoutes.markTripAsCompletedURL(tripId))
  }
}
