import actions from './hub-actions'

function getInitialState() {
  return {
    hubs: [],
    hubsWithUserGroups: {}
  }
}

export const hub = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetHubStore(state) {
      const initialState = getInitialState()
      for (var key in state) {
        state[key] = initialState[key]
      }
    },
    setHubs(state, value) {
      state.hubs = value
    },
    setHubsWithUserGroups(state, value) {
      state.hubsWithUserGroups = value
    }
  },

  actions,

  getters: {
    hubs: state => state.hubs,
    hubIds: state => state.hubs.map(hub => hub.id),
    getHubUserGroups: state => hubId => state.hubsWithUserGroups[hubId] || [],
    getHub: state => hubId => {
      const hub = state.hubs.find(hub => hub.id === hubId)
      if (hub) {
        return hub
      }
      return null
    },
    getHubByShortCode: state => hubShortCode => {
      const hub = state.hubs.find(hub => hub.shortCode === hubShortCode)
      if (hub) {
        return hub
      }
      return null
    },
    getHubShortCode: (_state, getters) => hubId => {
      const hub = getters.getHub(hubId)
      if (hub) {
        return hub.shortCode.split('-')[0]
      }
      return hubId
    },
    getHubId: (_state, getters) => hubShortCode => {
      const hub = getters.getHubByShortCode(hubShortCode)
      if (hub) {
        return hub.id
      }
      return hubShortCode
    }
  }
}
