import actions from './api-actions'

export const api = {
  namespaced: true,

  state: {
    loadingMapper: {},
    errorMessageMapper: {},
    errorCodeMapper: {}
  },

  mutations: {
    setLoading(state, { type, value }) {
      state.loadingMapper = {
        ...state.loadingMapper,
        [type]: value
      }
    },
    setErrorMessage(state, { type, value }) {
      state.errorMessageMapper = {
        ...state.errorMessageMapper,
        [type]: value
      }
    },
    setErrorCode(state, { type, value }) {
      state.errorCodeMapper = {
        ...state.errorCodeMapper,
        [type]: value
      }
    }
  },

  actions,

  getters: {
    getLoading: state => (...types) => {
      return types.some(type => state.loadingMapper[type])
    },
    isAnyLoading: (_state, getters) => {
      return getters.isAnyLoadingExcept()
    },
    isAnyLoadingExcept: (state) => (...types) => {
      for (const key in state.loadingMapper) {
        if (types.includes(key)) continue

        if (state.loadingMapper[key]) {
          return true
        }
      }
      return false
    },
    getErrorMessage: state => (type) => state.errorMessageMapper[type],
    getErrorCode: state => (type) => state.errorCodeMapper[type]
  }
}
