import { TRIP_STATES } from '@js/constants'

export function cloneTrips(trips) {
  return trips.map(trip => {
    const sortedTasks = trip.tasks.concat().sort((a, b) => {
      return a.priority - b.priority
    })
    const tasksClone = sortedTasks.map(task => ({ ...task }))
    return {
      ...trip,
      tasks: tasksClone
    }
  })
}

export function formatNewTrips(newTrips) {
  return newTrips.map(trip => {
    return {
      leaveBy: trip.leaveBy,
      tasks: formatTripTasks(trip.tasks)
    }
  })
}

export function formatExistingTrips(existingTrips) {
  return existingTrips.map(trip => {
    return {
      tripId: trip.id,
      tasks: formatTripTasks(trip.tasks)
    }
  })
}

function formatTripTasks(tasks) {
  return tasks.map((task, i) => {
    return {
      taskId: task.id,
      priority: i + 1
    }
  })
}

export function isTripDispatched(trip) {
  if (trip.isNew) {
    return false
  }
  return ![TRIP_STATES.ASSIGNED, TRIP_STATES.PACKED].includes(trip.state)
}
