import actions from './filters-actions'

function getInitialState() {
  return {
    selectedDate: new Date(),
    selectedTimeRange: [],
    selectedHubIds: []
  }
}

export const filters = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetFiltersStore(state) {
      const initialState = getInitialState()
      for (var key in state) {
        state[key] = initialState[key]
      }
    },
    setSelectedDate(state, value) {
      state.selectedDate = value
    },
    setSelectedTimeRange(state, value) {
      state.selectedTimeRange = value
    },
    setSelectedHubIds(state, value) {
      state.selectedHubIds = value
    }
  },

  actions,

  getters: {
    selectedDate: state => state.selectedDate,
    selectedTimeRange: state => state.selectedTimeRange,
    selectedHubIds: state => state.selectedHubIds
  }
}
