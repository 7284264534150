import Vue from 'vue'
import actions from './task-actions'

function getInitialState() {
  return {
    highlightedTask: null,
    tasks: [],
    groupedTaskIds: {},
    searchTasks: [],
    tasksMapper: {},
    taskOrderIdentifier: null,
    reassigningTask: null
  }
}

export const task = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetTaskStore(state) {
      const initialState = getInitialState()
      for (var key in state) {
        state[key] = initialState[key]
      }
    },
    setHighlightedTask(state, value) {
      state.highlightedTask = value
    },
    setTasks(state, value) {
      state.tasks = value
    },
    setGroupedTaskIds(state, value) {
      state.groupedTaskIds = value || {}
    },
    setSearchTasks(state, value) {
      state.searchTasks = value
    },
    setTaskOrderIdentifier(state, value) {
      state.taskOrderIdentifier = value
    },
    setTask(state, value) {
      if (value) {
        state.taskOrderIdentifier = value.orderIdentifier
        Vue.set(state.tasksMapper, value.orderIdentifier, value)
      } else {
        state.taskOrderIdentifier = null
      }
    },
    setReassigningTask(state, value) {
      state.reassigningTask = value
    },
    addTask(state, value) {
      state.tasks.push(value)
    },
    updateTasks(state, value) {
      [state.tasks, state.searchTasks].forEach(tasks => {
        value.forEach(updatedTask => {
          const taskIndex = tasks.findIndex(task => task.id === updatedTask.id)
          if (taskIndex !== -1) {
            Vue.set(tasks, taskIndex, updatedTask)
          }
        })
      })

      if (state.task) {
        // break loop once state.task is matched
        value.some(updatedTask => {
          if (state.task.id === updatedTask.id) {
            state.task = updatedTask
            return true
          }
          return false
        })
      }
    },
    removeTask(state, value) {
      const taskIndex = state.tasks.findIndex(task => task.id === value.id)
      if (taskIndex !== -1) {
        state.tasks.splice(taskIndex, 1)
      }
    }
  },

  actions,

  getters: {
    highlightedTask: state => state.highlightedTask,
    tasks: state => state.tasks,
    getTasks: state => (...taskIds) => {
      return state.tasks.filter(task => taskIds.includes(task.id))
    },
    groupedTaskIds: state => state.groupedTaskIds,
    searchTasks: state => state.searchTasks,
    task: state => {
      if (state.taskOrderIdentifier) {
        return state.tasksMapper[state.taskOrderIdentifier]
      }
      return null
    },
    reassigningTask: state => state.reassigningTask
  }
}
