import ApiRoutes from './ApiRoutes'
import HTTP from './HTTPService'

export default {
  getUsers(types) {
    return HTTP.get(ApiRoutes.filteredUsersURL(), { types })
  },
  newUser() {
    return HTTP.get(ApiRoutes.newUserURL())
  },
  createUser(user) {
    return HTTP.post(ApiRoutes.usersURL(), user)
  },
  updateUser(userId, user) {
    return HTTP.patch(ApiRoutes.userURL(userId), user)
  }
}
