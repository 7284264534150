const apiPath = (path) => [process.env.VUE_APP_SPINE_URL, '/', path].join('')
const SPINE_API_VERSION_ROOT = 'api/v4'
const MAYO_API_VERSION_ROOT = `${SPINE_API_VERSION_ROOT}/mayo`

export default {
  checkAuthURL: () => apiPath(`${SPINE_API_VERSION_ROOT}/check_auth`),
  loginURL: () => apiPath(`${SPINE_API_VERSION_ROOT}/login`),
  logoutURL: () => apiPath(`${SPINE_API_VERSION_ROOT}/logout`),
  profileURL: () => apiPath(`${SPINE_API_VERSION_ROOT}/account/profile`),
  userAccessURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/user_access`),

  usersURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/admin/users`),
  filteredUsersURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/admin/users/filtered`),
  newUserURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/admin/users/new`),
  userURL: (userId) => apiPath(`${MAYO_API_VERSION_ROOT}/admin/users/${userId}`),
  toggleActiveUserURL: (userId) => apiPath(`${MAYO_API_VERSION_ROOT}/admin/users/${userId}/toggle_active`),

  hubsURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/hubs`),
  timeslotsURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/timeslots`),

  incomingTasksURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/incoming`),
  tasksURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/filtered`),
  printTasksURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/print`),
  searchTasksURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/search`),
  markTasksAsLateURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/mark_as_late`),
  markTasksAsFailedURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/mark_as_failed`),
  markTasksAsCompletedURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/mark_as_completed`),
  taskByIdentifierURL: (identifier) => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/by_identifier/${identifier}`),
  unassignTaskURL: (taskId) => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/${taskId}/unassign_task`),
  createTaskCommentURL: (taskId) => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/${taskId}/create_comment`),
  assignTaskToPickuppURL: (taskId) => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/${taskId}/create_3p_logistics_order`),
  batchAssignTasksToPickuppURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/batch_create_3p_logistics_orders`),
  courierNameOverrideURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/add_courier_name_override`),
  reassignTaskToPickuppURL: (taskId) => apiPath(`${MAYO_API_VERSION_ROOT}/tasks/${taskId}/reassign_task_to_third_party_courier`),

  sortedCouriersURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/couriers/sorted`),

  tripsURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/trips/filtered`),
  assignTripsURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/trips/assign_trips`),
  updateTripsURL: () => apiPath(`${MAYO_API_VERSION_ROOT}/trips/update_trips`),
  tripURL: (tripId) => apiPath(`${MAYO_API_VERSION_ROOT}/trips/${tripId}`),
  reassignTripURL: (tripId) => apiPath(`${MAYO_API_VERSION_ROOT}/trips/${tripId}/reassign_trip`),
  updateTripLeaveByURL: (tripId) => apiPath(`${MAYO_API_VERSION_ROOT}/trips/${tripId}/update_leave_by`),
  updateTripPackerNameURL: (tripId) => apiPath(`${MAYO_API_VERSION_ROOT}/trips/${tripId}/update_packer_name`),
  updateTripPackedURL: (tripId) => apiPath(`${MAYO_API_VERSION_ROOT}/trips/${tripId}/update_packed`),
  updateTripStartedURL: (tripId) => apiPath(`${MAYO_API_VERSION_ROOT}/trips/${tripId}/update_started`),
  updateTripNotesURL: (tripId) => apiPath(`${MAYO_API_VERSION_ROOT}/trips/${tripId}/update_notes`),
  markTripAsCompletedURL: (tripId) => apiPath(`${MAYO_API_VERSION_ROOT}/trips/${tripId}/mark_as_completed`)
}
