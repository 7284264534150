import actions from './session-user-actions'

function getInitialState() {
  return {
    isAuthChecked: false,
    isLoggedIn: false,
    userId: null,
    userAccess: null,
    profile: null
  }
}

export const sessionUser = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetSessionUserStore(state) {
      const initialState = getInitialState()
      for (var key in state) {
        state[key] = initialState[key]
      }
    },
    setAuthChecked(state, value) {
      state.isAuthChecked = value
    },
    setLoggedIn(state, value) {
      state.isLoggedIn = value
    },
    setUserId(state, value) {
      state.userId = value
    },
    setUserAccess(state, value) {
      state.userAccess = value
    },
    setProfile(state, value) {
      state.profile = value
    }
  },

  actions,

  getters: {
    isAuthChecked: (state) => state.isAuthChecked,
    isLoggedIn: (state) => state.isLoggedIn,
    userId: state => state.userId,
    profile: state => state.profile,
    role: state => state.userAccess && state.userAccess.clearanceLevel,
    features: state => state.userAccess && state.userAccess.features
  }
}
