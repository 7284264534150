import SessionUserApi from '@/store/transport/SessionUserApi'
import cookies from '@js/cookies'
import router from '@/router'

export default {
  resetSessionUserStore({ commit }) {
    commit('resetSessionUserStore')
  },
  checkAuthRequest({ dispatch }) {
    SessionUserApi.checkAuth().then(
      (response) => {
        dispatch('checkAuthSuccess', response)
      },
      (reason) => {
        dispatch('checkAuthFailed', reason)
      }
    )
  },
  checkAuthSuccess({ commit, dispatch }, response) {
    commit('setUserId', response.userId)
    commit('setLoggedIn', true)
    commit('setAuthChecked', true)

    dispatch('getUserAccessRequest')
  },
  checkAuthFailed({ commit }, reason) {
    commit('setUserId', reason.userId)
    commit('setLoggedIn', false)
    commit('setAuthChecked', true)
  },
  getUserAccessRequest({ dispatch }) {
    SessionUserApi.getUserAccess().then(
      (response) => {
        dispatch('getUserAccessSuccess', response)
      },
      (reason) => {
        dispatch('getUserAccessFailed', reason)
      }
    )
  },
  getUserAccessSuccess({ commit }, response) {
    delete response._status
    commit('setUserAccess', response)
  },
  getUserAccessFailed() {},
  loginRequest({ dispatch }, user) {
    SessionUserApi.login(user).then(
      (response) => {
        dispatch('loginSuccess', response)
      },
      (reason) => {
        let message = ''
        for (var key in reason) {
          message += `${key} ${reason[key]}`
        }
        dispatch('loginFailed', message)
      }
    )
  },
  loginSuccess({ commit, dispatch }, response) {
    commit('setLoggedIn', true)
    commit('setUserId', response.profile.id)
    commit('setProfile', response.profile)
    cookies.setCookie('external_access_token', response.accessToken.token)
    cookies.setCookie('access_level', response.profile.accessLevel)

    dispatch('getUserAccessRequest')
  },
  loginFailed() {},
  logoutRequest({ dispatch }) {
    SessionUserApi.logout().then(
      (response) => {
        dispatch('logoutSuccess', response)
      },
      (reason) => {
        dispatch('logoutFailed', reason)
      }
    )
  },
  logoutSuccess({ dispatch }) {
    dispatch('postLogoutActions')
  },
  logoutFailed({ dispatch }) {
    // we will assume the logout passed, even if it failed. this clears the external_access_token, which will require user to login again
    dispatch('postLogoutActions')
  },
  getProfileRequest({ dispatch }) {
    SessionUserApi.getProfile().then(
      (response) => {
        dispatch('getProfileSuccess', response)
      },
      (reason) => {
        dispatch('getProfileFailed', reason)
      }
    )
  },
  getProfileSuccess({ commit }, response) {
    commit('setProfile', response.profile)
  },
  getProfileFailed() {},
  postLogoutActions({ commit }) {
    commit('setLoggedIn', false)
    commit('setUserAccess', null)
    commit('setProfile', null)
    // delete cookies used for authentication and authorization
    cookies.deleteCookie('access_level')
    cookies.deleteCookie('external_access_token')
    cookies.deleteCookie('session_user_uuid')
    // redirect after logout
    router.replace({ name: 'login' })
  }
}
