import TripApi from '@/store/transport/TripApi'
import store from '@/store'

export default {
  resetTripStore({ commit }) {
    commit('resetTripStore')
  },
  getTripsRequest({ dispatch }, data) {
    TripApi.getTrips(data).then(
      (response) => {
        dispatch('getTripsSuccess', { response, data })
      },
      (reason) => {
        dispatch('getTripsFailed', reason)
      }
    )
  },
  getTripsSuccess({ commit }, { response, data }) {
    if (data.dispatched) {
      commit('setDispatchedTrips', response.trips)
    } else {
      commit('setTrips', response.trips)
      commit('setGroupedTripIds', response.groupedTripIds)
    }
  },
  getTripsFailed() {},
  assignTripsRequest({ dispatch }, data) {
    TripApi.assignTrips(data).then(
      (response) => {
        dispatch('assignTripsSuccess', response)
      },
      (reason) => {
        dispatch('assignTripsFailed', reason)
      }
    )
  },
  assignTripsSuccess({ commit }, response) {
    delete response._status
    const trips = Object.values(response)
    commit('addTrips', trips)

    // remove newly assigned tasks from left panel
    trips.forEach(trip => {
      trip.tasks.forEach(task => {
        store.dispatch('task/removeTask', task)
      })
    })
  },
  assignTripsFailed() {},
  updateTripsRequest({ dispatch }, data) {
    TripApi.updateTrips(data).then(
      (response) => {
        dispatch('updateTripsSuccess', response)
      },
      (reason) => {
        dispatch('updateTripsFailed', reason)
      }
    )
  },
  updateTripsSuccess({ commit }, response) {
    delete response._status
    const trips = Object.values(response)
    commit('updateTrips', trips)

    // remove newly assigned tasks from left panel
    trips.forEach(trip => {
      trip.tasks.forEach(task => {
        store.dispatch('task/removeTask', task)
      })
    })
  },
  updateTripsFailed() {},
  resetAssigningTrips({ commit }) {
    commit('setTripPolyline', null)
    commit('resetAssigningTrips')
    store.dispatch('courier/setSelectedCourier', null)
    store.dispatch('task/setReassigningTask', null)
  },
  setAssigningTrips({ commit }, value) {
    commit('setAssigningTrips', value)
  },
  setReassigningTripId({ commit }, value) {
    commit('setReassigningTripId', value)
  },
  addNewTrip({ commit }) {
    commit('addNewTrip')
  },
  selectTrip({ commit }, tripId) {
    commit('selectTrip', tripId)
  },
  reassignTripRequest({ dispatch }, { tripId, specialistId }) {
    TripApi.reassignTrip(tripId, specialistId).then(
      (response) => {
        dispatch('reassignTripSuccess', response)
      },
      (reason) => {
        dispatch('reassignTripFailed', reason)
      }
    )
  },
  reassignTripSuccess({ commit }, response) {
    delete response._status
    commit('updateTrips', [response])
  },
  reassignTripFailed() {},
  updateTripLeaveBy({ commit, dispatch }, value) {
    if (value.isNew) {
      commit('updateTripLeaveBy', value)
    } else {
      TripApi.updateTripLeaveBy(value.tripId, value.leaveBy).then(
        (response) => {
          dispatch('updateTripLeaveBySuccess', response)
        },
        (reason) => {
          dispatch('updateTripLeaveByFailed', reason)
        }
      )
    }
  },
  updateTripLeaveBySuccess({ commit }, response) {
    delete response._status
    commit('updateTrips', [response])
  },
  updateTripLeaveByFailed() {},
  updateTripPackerNameRequest({ dispatch }, { tripId, packerName }) {
    TripApi.updateTripPackerName(tripId, packerName).then(
      (response) => {
        dispatch('updateTripPackerNameSuccess', response)
      },
      (reason) => {
        dispatch('updateTripPackerNameFailed', reason)
      }
    )
  },
  updateTripPackerNameSuccess({ commit }, response) {
    delete response._status
    commit('updateTrips', [response])
  },
  updateTripPackerNameFailed() {},
  updateTripPackedRequest({ dispatch }, { tripId, pack }) {
    TripApi.updateTripPacked(tripId, pack).then(
      (response) => {
        dispatch('updateTripPackedSuccess', response)
      },
      (reason) => {
        dispatch('updateTripPackedFailed', reason)
      }
    )
  },
  updateTripPackedSuccess({ commit }, response) {
    delete response._status
    commit('updateTrips', [response])
  },
  updateTripPackedFailed() {},
  updateTripStartedRequest({ dispatch }, { tripId, start }) {
    TripApi.updateTripStarted(tripId, start).then(
      (response) => {
        dispatch('updateTripStartedSuccess', response)
      },
      (reason) => {
        dispatch('updateTripStartedFailed', reason)
      }
    )
  },
  updateTripStartedSuccess({ commit }, response) {
    delete response._status
    commit('updateDispatchTrips', [response])
  },
  updateTripStartedFailed() {},
  updateTripNotesRequest({ dispatch }, { tripId, notes }) {
    TripApi.updateTripNotes(tripId, notes).then(
      (response) => {
        dispatch('updateTripNotesSuccess', response)
      },
      (reason) => {
        dispatch('updateTripNotesFailed', reason)
      }
    )
  },
  updateTripNotesSuccess({ commit }, response) {
    delete response._status
    commit('updateTrips', [response])
  },
  updateTripNotesFailed() {},
  markTripAsCompletedRequest({ dispatch }, tripId) {
    TripApi.markTripAsCompleted(tripId).then(
      (response) => {
        dispatch('markTripAsCompletedSuccess', response)
      },
      (reason) => {
        dispatch('markTripAsCompletedFailed', reason)
      }
    )
  },
  markTripAsCompletedSuccess({ commit }, response) {
    delete response._status
    commit('updateTrips', [response])
  },
  markTripAsCompletedFailed() {},
  getTripRequest({ dispatch }, tripId) {
    TripApi.getTrip(tripId).then(
      (response) => {
        dispatch('getTripSuccess', response)
      },
      (reason) => {
        dispatch('getTripFailed', reason)
      }
    )
  },
  getTripSuccess({ commit }, response) {
    delete response._status
    commit('setTrip', response)
  },
  getTripFailed() {},
  removeTrip({ commit, dispatch }, value) {
    if (value.isNew) {
      commit('removeTrip', value.id)
    } else {
      TripApi.removeTrip(value.id).then(
        (response) => {
          dispatch('removeTripSuccess', {
            response,
            trip: value
          })
        },
        (reason) => {
          dispatch('removeTripFailed', reason)
        }
      )
    }
  },
  removeTripSuccess({ commit }, { trip }) {
    commit('removeTrip', trip.id)

    // add unassigned tasks to left panel
    trip.tasks.forEach(task => {
      store.dispatch('task/addTask', {
        ...task,
        trip: null,
        tripId: null
      })
    })
  },
  removeTripFailed() {},
  setTripTasks({ commit }, value) {
    commit('setTripTasks', value)
  },
  addTripTask({ commit, state }, task) {
    // create new trip if no new trip exists yet
    if (!state.assigningTrips[state.selectedTripIndex]) {
      commit('addNewTrip')
      store.dispatch('courier/setSelectedCourier', null)
    }
    commit('addTripTask', task)
  },
  removeTripTask({ commit, dispatch, state, getters }, task) {
    // call API to remove task if is from existing trip
    const trip = state.trips.find(trip => {
      return trip.tasks.find(tripTask => tripTask.id === task.id)
    })
    if (trip) {
      // if only 1 task is left in the trip, remove trip
      if (trip.tasks.length === 1) {
        dispatch('removeTrip', trip)
      } else {
        store.dispatch('task/unassignTaskRequest', task.id)
      }

      // skip update store if reassigning task
      if (store.getters['task/reassigningTask'] === task) return
    }

    commit('removeTripTask', task)
    // reset if no more tasks left
    if (getters.assigningTripTasks.length === 0) {
      dispatch('resetAssigningTrips')
    }

    // removeTrip will add trip tasks to the left panel, so skip if removing trip (tasks length is 1)
    if (trip && trip.tasks.length !== 1) {
      // add unassigned task to left panel
      store.dispatch('task/addTask', {
        ...task,
        trip: null,
        tripId: null
      })
    }
  },
  setTripPolyline({ commit }, path) {
    commit('setTripPolyline', path)
  },
  setCachedTrip({ commit }, value) {
    commit('setCachedTrip', value)
  }
}
