import ApiRoutes from './ApiRoutes'
import HTTP from './HTTPService'

export default {
  getIncomingTasks(data) {
    return HTTP.get(ApiRoutes.incomingTasksURL(), data)
  },
  getTasks(data) {
    return HTTP.get(ApiRoutes.tasksURL(), data)
  },
  searchTasks(data) {
    return HTTP.get(ApiRoutes.searchTasksURL(), data)
  },
  markTasksAsLate(data) {
    return HTTP.patch(ApiRoutes.markTasksAsLateURL(), data)
  },
  markTasksAsFailed(data) {
    return HTTP.patch(ApiRoutes.markTasksAsFailedURL(), data)
  },
  markTasksAsCompleted(data) {
    return HTTP.patch(ApiRoutes.markTasksAsCompletedURL(), data)
  },
  getTaskByIdentifier(identifier) {
    return HTTP.get(ApiRoutes.taskByIdentifierURL(identifier))
  },
  unassignTask(taskId) {
    return HTTP.patch(ApiRoutes.unassignTaskURL(taskId))
  },
  createTaskComment(taskId, comment) {
    return HTTP.post(ApiRoutes.createTaskCommentURL(taskId), { comment })
  },
  assignTaskToPickupp(taskId) {
    return HTTP.post(ApiRoutes.assignTaskToPickuppURL(taskId))
  },
  batchAssignTasksToPickupp(taskIds) {
    return HTTP.post(ApiRoutes.batchAssignTasksToPickuppURL(), { taskIds })
  },
  courierNameOverride(courierName, orderIdentifiers) {
    return HTTP.patch(ApiRoutes.courierNameOverrideURL(), { courierName, orderIdentifiers })
  },
  reassignTaskToPickupp(taskId) {
    return HTTP.patch(ApiRoutes.reassignTaskToPickuppURL(taskId))
  }
}
