import CourierApi from '@/store/transport/CourierApi'
import store from '@/store'

export default {
  resetCourierStore({ commit }) {
    commit('resetCourierStore')
  },
  getSortedCouriersRequest({ dispatch }, data) {
    CourierApi.getSortedCouriers(data).then(
      (response) => {
        dispatch('getSortedCouriersSuccess', response)
      },
      (reason) => {
        dispatch('getSortedCouriersFailed', reason)
      }
    )
  },
  getSortedCouriersSuccess({ commit }, response) {
    commit('setCouriers', response.couriers)
    commit('setGroupedCourierIds', response.groupedCourierIds)
  },
  getSortedCouriersFailed() {},
  getCouriersRequest({ dispatch }) {
    const courierTypes = store.getters['user/courierTypes']
    CourierApi.getCouriers(courierTypes).then(
      (response) => {
        dispatch('getCouriersSuccess', response)
      },
      (reason) => {
        dispatch('getCouriersFailed', reason)
      }
    )
  },
  getCouriersSuccess({ commit }, response) {
    delete response._status
    commit('setCouriers', Object.values(response))
  },
  getCouriersFailed() {},
  createCourierRequest({ dispatch }, courier) {
    CourierApi.createCourier(courier).then(
      (response) => {
        dispatch('createCourierSuccess', response)
      },
      (reason) => {
        dispatch('createCourierFailed', reason)
      }
    )
  },
  createCourierSuccess({ commit }, response) {
    delete response._status
    commit('addCouriers', [response])
  },
  createCourierFailed() {},
  updateCourierRequest({ dispatch }, { userId, courier }) {
    CourierApi.updateCourier(userId, courier).then(
      (response) => {
        dispatch('updateCourierSuccess', response)
      },
      (reason) => {
        dispatch('updateCourierFailed', reason)
      }
    )
  },
  updateCourierSuccess({ commit }, response) {
    delete response._status
    commit('updateCouriers', [response])
  },
  updateCourierFailed() {},
  toggleCourierActiveRequest({ dispatch }, { userId, isActive }) {
    CourierApi.toggleCourierActive(userId, isActive).then(
      (response) => {
        dispatch('toggleCourierActiveSuccess', response)
      },
      (reason) => {
        dispatch('toggleCourierActiveFailed', reason)
      }
    )
  },
  toggleCourierActiveSuccess({ commit }, response) {
    delete response._status
    commit('updateCouriers', [response])
  },
  toggleCourierActiveFailed() {},
  setSelectedCourier({ commit }, value) {
    commit('setSelectedCourier', value)
  }
}
