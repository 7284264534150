export default {
  common: {
    loading: 'Loading...',
    home: 'Home',
    all: 'All',
    actions: {
      create: 'Create',
      edit: 'Edit',
      update: 'Update',
      destroy: 'Remove'
    },
    task: {
      shortId: {
        pickupp: 'PICKUPP {shortId}',
        lalamove: 'LLM {shortId}'
      }
    }
  },
  navbar: {
    dateSelector: {
      placeholder: 'Select date'
    },
    timeRangeSelector: {
      customRangeLabel: 'Customise',
      morningRangeLabel: 'Before 2:30PM',
      afternoonRangeLabel: '2:30PM-6:00PM',
      eveningRangeLabel: 'After 6:00PM'
    },
    hubSelector: {
      placeholder: 'Select hub'
    },
    navs: {
      main: {
        assignment: 'Map',
        print: 'Print',
        packAndDispatch: 'Pack & Dispatch',
        tasks: 'Orders'
      },
      fulfilment: {
        title: 'Fulfilment',
        couriersManagement: 'Couriers management',
        courierBilling: 'Courier billing'
      },
      admin: {
        title: 'Administrators',
        usersManagement: 'Users management'
      },
      logout: 'Logout'
    }
  },
  login: {
    header: 'Welcome to Grain Mayo',
    emailLabel: 'Email',
    passwordLabel: 'Password',
    loginLabel: 'Sign in',
    forgotPasswordLabel: 'Forgot your password?'
  },
  home: {
    header: 'Hello {user}, welcome to Mayo',
    message: 'Select a page to start your day'
  },
  assignment: {
    leftPanel: {
      header: 'Timeslots',
      hideEmptyTimeslots: 'Hide empty timeslots',
      timeslot: {
        ordersCount: '1 order | {count} orders'
      },
      task: {
        assignPickupp: 'Assign to Pickupp',
        assignLalamove: 'Assign to Lalamove',
        premiumDelivery: 'Premium delivery',
        overweight: '>20kg order'
      }
    },
    rightPanel: {
      header: 'Assigned orders',
      emptyTrips: 'No trips assigned.',
      ordersLeft: '1 more order to deliver | {count} more orders to deliver',
      edit: 'Edit',
      assign: {
        header: 'Journey',
        tasksCount: '1 order | {count} orders',
        cancel: {
          title: 'Cancel assignment?',
          message: 'Any unsaved changes will be lost.',
          confirm: 'Yes, cancel',
          cancel: 'No'
        },
        searchCouriers: {
          placeholder: 'Assign to',
          noResults: 'No results found'
        },
        buttonTitle: 'Assign',
        deselectAll: {
          title: 'Deselect all?',
          message: 'You are about to unassign all listed trips and orders.',
          confirm: 'Deselect all',
          cancel: 'Back'
        },
        batchAssignToPickupp: 'Batch Pickupp',
        batchAssignToLalamove: 'Batch Lalamove',
        successMessage: 'Assign success',
        reassignSuccessMessage: 'Reassign success',
        error: {
          noCourier: 'No courier selected.',
          reassignSameCourier: 'Must reassign to a different courier.',
          tripStarted: 'Trip has already been dispatched.'
        },
        leaveBy: {
          title: 'Leave by {time}',
          0: '0 minutes before serving time',
          15: '15 minutes before serving time',
          30: '30 minutes before serving time',
          45: '45 minutes before serving time',
          60: '1 hour before serving time'
        },
        trip: {
          id: 'Trip {id}',
          tasksCount: '1 order | {count} orders',
          allTasksCompleted: 'All tasks completed.',
          remove: {
            title: 'Remove trip?',
            message: 'You are about to remove the trip. This action will deselect and unassign all orders within the trip.',
            confirm: 'Remove',
            cancel: 'Cancel'
          },
          startedAt: 'Started at {time}',
          add: '+ Add another trip',
          contextMenu: {
            reassign: 'Reassign trip to',
            markAsCompleted: 'Mark as completed'
          }
        },
        task: {
          remove: {
            title: 'Remove order?',
            message: 'You are about to remove the order. This action will deselect and unassign this order.',
            confirm: 'Remove',
            cancel: 'Cancel'
          },
          contextMenu: {
            checkPickupp: 'Check on Pickupp',
            checkLalamove: 'Check on Lalamove',
            reassign: 'Reassign task to',
            reassignToPickupp: 'Reassign task to Pickupp',
            reassignToLalamove: 'Reassign task to Lalamove'
          }
        }
      }
    },
    pickuppForm: {
      formHeader: {
        pickupp: 'Confirm Pickupp order',
        lalamove: 'Confirm Lalamove order'
      },
      formLabels: {
        hub: 'Hub',
        deliveryTime: 'Delivery time',
        customerDetails: 'Customer details',
        customerAddress: 'Customer address'
      },
      actions: {
        cancel: 'Cancel',
        confirm: 'Confirm'
      }
    }
  },
  print: {
    searchPlaceholder: 'Order ID, Customer name/number/address, Serving time',
    numBoxesPerPageLabel: 'No. of meal boxes for 1 page',
    printButtonTitle: 'Print',
    selectAllLabel: 'Select all',
    unprintedLabel: 'Ready to print',
    printedLabel: 'Printed',
    empty: {
      unprinted: 'No ready to print trips found.',
      printed: 'No printed trips found.'
    },
    trip: {
      title: '{specialist} • Trip {tripNum}',
      subtitle: 'Leave by {timeLabel} • {numTasksLabel}',
      pickupPointSubtitle: 'Serving from {timeLabel} • {numTasksLabel}', // currently unused
      numTasksLabel: '{count} order | {count} orders',
      task: {
        pickupLabel: 'Pickup',
        taskInfo: '{identifier} • {numOrderItemsLabel}',
        taskInfoWithShortId: '{shortId} • {taskInfo}',
        numOrderItemsLabel: '{count} item | {count} items',
        item: {
          quantity: 'x {count}'
        }
      }
    },
    courierNameOverrideForm: {
      formHeader: 'Set courier name override for tasks',
      formLabels: {
        courierName: 'Courier name',
        orderIdentifiers: 'Order identifiers (e.g. G542354)'
      },
      actions: {
        cancel: 'Cancel',
        confirm: 'Confirm'
      },
      successMessage: 'Courier name override success',
      failureMessage: 'Courier name override failed'
    }
  },
  packAndDispatch: {
    searchPlaceholder: 'Order ID, Courier name',
    tabs: {
      upcoming: 'Upcoming trips',
      dispatched: 'Dispatched trips'
    },
    table: {
      headers: {
        courier: 'Courier',
        orders: 'Total orders',
        packer: 'Packed by',
        leaveBy: 'Leave by',
        packedAt: 'Ready',
        startedAt: 'Out of hub',
        notes: 'Notes',
        status: 'Status'
      },
      empty: 'No trips found.',
      tripNum: 'Trip {tripNum}',
      tasks: {
        pickups: '1 pickup | {count} pickups',
        deliveries: '1 delivery | {count} deliveries',
        pickupsAndDeliveries: '{pickups}, {deliveries}',
        summaryWithShortId: '{shortId} • {summary}'
      },
      packerPlaceholder: 'Packer name',
      markPackedButtonTitle: 'Mark as ready',
      cancelPackedDisabledTooltip: 'Must cancel out of hub first.',
      markStartedButtonTitle: 'Mark as out',
      markStartedDisabledTooltip: 'Must be ready first.',
      notesPlaceholder: 'Add notes'
    },
    trip: {
      status: {
        late: '1 min late | {lateBy} mins late',
        completed: 'Completed',
        failed: 'Failed'
      },
      title: '<strong>{specialist}</strong> • Trip {tripNum}',
      deliveryGroupLabel: 'Delivery',
      groupTitle: '<strong>{name}</strong> • 1 order | <strong>{name}</strong> • {count} orders'
    }
  },
  tasks: {
    status: {
      cancelled: 'Cancelled',
      incoming: 'Incoming',
      assigned: 'Assigned',
      packed: 'Packed',
      on_courier: 'On courier',
      in_delivery: 'In delivery',
      ready_for_pickup: 'Ready for pickup',
      completed: 'Completed',
      failed: 'Failed',
      eta: '1 min away | {eta} mins away',
      late: 'Late by 1 min | Late by {lateBy} mins',
      completedAt: 'Completed at {time}'
    },
    tabs: {
      inProgress: 'Orders on the way',
      completed: 'Completed orders'
    },
    taskType: {
      title: 'Order type',
      all: 'All',
      delivery: 'Delivery orders',
      pickup: 'Pickup orders'
    },
    markAs: {
      title: 'Mark as',
      late: {
        lateBy: 'Mark as late by {lateBy} mins (notifies customer)',
        veryLate: 'Mark as very late (notifies CC)'
      },
      lateSuccess: 'Marked as late',
      failed: 'Mark as failed (notifies customer)',
      failedPrompt: {
        message: 'Failed reason:',
        confirmLabel: 'Mark as failed'
      },
      failedSuccess: 'Marked as failed',
      completed: 'Mark as completed',
      completedPrompt: {
        message: 'Additional notes (optional):',
        confirmLabel: 'Mark as completed'
      },
      completedSuccess: 'Marked as completed'
    },
    search: {
      title: 'Search',
      headerTitle: 'Orders > Search',
      empty: 'Search by order number, courier name, customer account or deliver address',
      searching: 'Searching...'
    },
    table: {
      headers: {
        orderNumber: 'Order number',
        status: 'Status',
        servingTime: 'Serving time',
        servingDateTime: 'Serving time and date',
        courier: 'Courier details',
        address: 'Delivery address',
        customerDetails: 'Customer details',
        completedAt: 'Completed at'
      },
      empty: 'No orders found.',
      task: {
        pickupLabel: 'Pickup'
      }
    },
    taskDetails: {
      headerTitle: 'Orders > {id}',
      edit: 'Edit on Spine',
      checkPickupp: 'Check on Pickupp',
      checkLalamove: 'Check on Lalamove',
      info: {
        tags: {
          pickup: 'Pickup'
        },
        servingDateTime: {
          label: 'Serving time and date',
          content: '{time}, {date}'
        },
        courier: {
          label: 'Courier details'
        },
        address: {
          label: 'Delivery address'
        },
        customer: {
          label: 'Customer details'
        },
        items: {
          label: 'Order items',
          content: '{name} x {quantity}',
          meals: {
            quantity: 'x {quantity}'
          }
        },
        operationHistory: {
          header: 'Operation history',
          showMoreComments: 'Show more comments',
          showAllComments: 'All comments ({count} more)',
          commentButtonTitle: 'Comment',
          createCommentSuccess: 'Comment added'
        }
      },
      unassigned: 'This order has not been assigned yet.'
    }
  },
  users: {
    table: {
      empty: 'No users found.'
    },
    filter: {
      allHubs: 'All hubs',
      allRoles: 'All roles'
    },
    actions: {
      createAccount: 'Create account',
      createSuccess: 'User created',
      updateSuccess: 'User updated'
    },
    form: {
      header: {
        new: 'New account',
        edit: 'Edit account'
      },
      role: 'Role',
      hubs: 'Hubs',
      name: 'Name',
      email: 'Email',
      mobileNumber: 'Mobile number',
      password: 'Password',
      tips: {
        customerCare: 'Customer care will automatically be under all hubs',
        existingSpineUser: 'You can leave Name, Mobile number, and Password empty, if Email already exists on Spine.'
      }
    }
  },
  couriers: {
    table: {
      headers: {
        internal: 'Internal couriers',
        active: 'Active',
        mobileNumber: 'Mobile',
        hubs: 'Hubs'
      },
      empty: 'No couriers found.'
    },
    actions: {
      createAccount: 'New courier',
      createSuccess: 'Courier created',
      updateSuccess: 'Courier updated'
    },
    form: {
      header: {
        new: 'New courier',
        edit: 'Edit courier'
      },
      userGroups: 'Hub user groups',
      name: 'Name',
      email: 'Email',
      mobileNumber: 'Mobile number',
      password: 'Password'
    }
  },
  strongConfirmationDialog: {
    default: {
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    },
    cancelPickupp: {
      message: 'This action will cancel the arrangement with Pickupp, and may incur a penalty. If you are sure, type in <strong>cancel</strong> to enable the cancel button.',
      lalamoveMessage: 'This action will cancel the arrangement with Lalamove, and may incur a penalty. If you are sure, type in <strong>cancel</strong> to enable the cancel button.',
      input: 'cancel',
      confirmText: 'Yes, cancel',
      cancelText: 'No'
    },
    cancelTripWithPickupp: {
      message: 'Trip includes a task that was assigned to Pickupp. This action will cancel the arrangement with Pickupp, and may incur a penalty. If you are sure, type in <strong>cancel</strong> to enable the cancel button.',
      lalamoveMessage: 'Trip includes a task that was assigned to Lalamove. This action will cancel the arrangement with Lalamove, and may incur a penalty. If you are sure, type in <strong>cancel</strong> to enable the cancel button.'
    }
  }
}
