import HubApi from '@/store/transport/HubApi'

export default {
  resetHubStore({ commit }) {
    commit('resetHubStore')
  },
  getHubsRequest({ dispatch }, data) {
    HubApi.getHubs(data).then(
      (response) => {
        dispatch('getHubsSuccess', response)
      },
      (reason) => {
        dispatch('getHubsFailed', reason)
      }
    )
  },
  getHubsSuccess({ commit }, response) {
    commit('setHubs', response.hubs)
  },
  getHubsFailed() {}
}
