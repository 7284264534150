import eventBus from '@/event-bus'

export const apiManagerMiddleware = store => {
  store.subscribeAction({
    after: emitAction
  })
  store.subscribeAction(loadingHandler)
  store.subscribeAction(errorHandler)

  function emitAction(action) {
    eventBus.$emit(action.type)
  }

  function loadingHandler(action, state) {
    const { type } = action
    const matches = /(.*)(Request|Success|Failed)/.exec(type)
    // ignore actions other than *Request / *Success /  *Failed
    if (!matches) return state
    const [, requestName, requestState] = matches
    store.dispatch('api/setLoading', {
      type: requestName,
      value: requestState === 'Request'
    })
  }

  function errorHandler(action, state) {
    const { type, message, payload } = action
    const matches = /(.*)(Request|Failed)/.exec(type)
    // ignore actions other than *Request / *Failed
    if (!matches) return state
    const [, requestName, requestState] = matches
    store.dispatch('api/setErrorMessage', {
      type: requestName,
      value: requestState === 'Failed' ? getErrorHandlerMessage(message, payload) : null
    })
    store.dispatch('api/setErrorCode', {
      type: requestName,
      value: requestState === 'Failed' ? getErrorHandlerCode(payload) : null
    })
  }

  function getErrorHandlerMessage(message, payload) {
    if (message) return message
    if (typeof payload === 'object') {
      if (payload.error) return payload.error
      if (payload.message) return payload.message
    }
    return payload
  }

  function getErrorHandlerCode(payload) {
    if (typeof payload === 'object') {
      return payload.code
    }
    return null
  }
}
