import Vue from 'vue'
import actions from './trip-actions'
import { cloneTrips } from '@js/trip-utils'

function getInitialState() {
  return {
    trips: [],
    dispatchedTrips: [],
    groupedTripIds: {},
    trip: null,
    assigningTrips: [],
    selectedTripIndex: 0,
    localTripId: 1,
    reassigningTripId: null,
    tripPolyline: {
      path: null,
      options: {
        strokeColor: 'white',
        strokeOpacity: 0.8,
        strokeWeight: 4
      }
    },
    cachedTrips: {}
  }
}

export const trip = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetTripStore(state) {
      const initialState = getInitialState()
      for (var key in state) {
        state[key] = initialState[key]
      }
    },
    setTrips(state, value) {
      state.trips = value
    },
    setDispatchedTrips(state, value) {
      state.dispatchedTrips = value
    },
    setGroupedTripIds(state, value) {
      state.groupedTripIds = value
    },
    setTrip(state, value) {
      state.trip = value
    },
    addTrips(state, value) {
      value.forEach(newTrip => {
        state.trips.push(newTrip)
      })
    },
    updateTrips(state, value) {
      value.forEach(updatedTrip => {
        const tripIndex = state.trips.findIndex(trip => trip.id === updatedTrip.id)
        if (tripIndex !== -1) {
          Vue.set(state.trips, tripIndex, updatedTrip)
        }
      })
    },
    updateDispatchTrips(state, value) {
      // on dispatch or cancel dispatch, move the updated trip to the correct tab
      value.forEach(updatedTrip => {
        const oldTrips = updatedTrip.startedAt ? state.trips : state.dispatchedTrips
        const newTrips = updatedTrip.startedAt ? state.dispatchedTrips : state.trips
        const tripIndex = oldTrips.findIndex(trip => trip.id === updatedTrip.id)
        if (tripIndex !== -1) {
          oldTrips.splice(tripIndex, 1)
          newTrips.push(updatedTrip)
        }
      })
    },
    resetAssigningTrips(state) {
      const initialState = getInitialState()
      state.assigningTrips = initialState.assigningTrips
      state.selectedTripIndex = initialState.selectedTripIndex
      state.localTripId = initialState.localTripId
      state.reassigningTripId = initialState.reassigningTripId
    },
    setAssigningTrips(state, value) {
      state.assigningTrips = value
    },
    setReassigningTripId(state, value) {
      state.reassigningTripId = value
    },
    addNewTrip(state) {
      state.assigningTrips.push({
        id: `New${state.localTripId++}`,
        leaveLeadTime: 30,
        tasks: [],
        isNew: true
      })
      state.selectedTripIndex = state.assigningTrips.length - 1
    },
    selectTrip(state, tripId) {
      state.selectedTripIndex = state.assigningTrips.findIndex(assigningTrip => tripId === assigningTrip.id)
    },
    updateTripLeaveBy(state, { tripId, leaveBy, leaveLeadTime }) {
      const tripIndex = state.assigningTrips.findIndex(assigningTrip => tripId === assigningTrip.id)
      if (tripIndex !== -1) {
        const trip = state.assigningTrips[tripIndex]
        trip.leaveBy = leaveBy
        trip.leaveLeadTime = leaveLeadTime
      }
    },
    removeTrip(state, tripId) {
      let tripIndex = state.trips.findIndex(trip => tripId === trip.id)
      if (tripIndex !== -1) {
        state.trips.splice(tripIndex, 1)
      }

      tripIndex = state.assigningTrips.findIndex(assigningTrip => tripId === assigningTrip.id)
      if (tripIndex !== -1) {
        state.assigningTrips.splice(tripIndex, 1)
        if (state.selectedTripIndex >= tripIndex) {
          state.selectedTripIndex--
        }
      }
    },
    setTripTasks(state, { tripId, tasks }) {
      const tripIndex = state.assigningTrips.findIndex(assigningTrip => tripId === assigningTrip.id)
      if (tripIndex !== -1) {
        const trip = state.assigningTrips[tripIndex]
        trip.tasks = tasks
      }
    },
    addTripTask(state, value) {
      state.assigningTrips[state.selectedTripIndex].tasks.push(value)
    },
    removeTripTask(state, value) {
      let taskIndex

      let tripIndex = state.trips.findIndex(trip => {
        taskIndex = trip.tasks.findIndex(task => task.id === value.id)
        return taskIndex !== -1
      })
      if (tripIndex !== -1) {
        const trip = state.trips[tripIndex]
        trip.tasks.splice(taskIndex, 1)
      }

      tripIndex = state.assigningTrips.findIndex(assigningTrip => {
        taskIndex = assigningTrip.tasks.findIndex(task => task.id === value.id)
        return taskIndex !== -1
      })
      if (tripIndex !== -1) {
        const trip = state.assigningTrips[tripIndex]
        trip.tasks.splice(taskIndex, 1)
      }
    },
    setTripPolyline(state, value) {
      state.tripPolyline.path = value
    },
    setCachedTrip(state, { tripFingerprint, response }) {
      state.cachedTrips[tripFingerprint] = response
    }
  },

  actions,

  getters: {
    trips: state => state.trips,
    getTrip: state => tripId => {
      if (tripId) {
        return state.trips.find(trip => trip.id === tripId)
      }
      return null
    },
    dispatchedTrips: state => state.dispatchedTrips,
    groupedTripIds: state => state.groupedTripIds,
    trip: state => state.trip,
    tripsTasks: state => {
      return state.trips.reduce((accumulated, trip) => {
        return accumulated.concat(trip.tasks)
      }, [])
    },
    assigningTrips: state => state.assigningTrips,
    assigningTripTasks: state => {
      return state.assigningTrips.reduce((accumulated, assigningTrip) => {
        return accumulated.concat(assigningTrip.tasks)
      }, [])
    },
    selectedAssigningTrip: state => state.assigningTrips[state.selectedTripIndex],
    reassigningTripId: state => state.reassigningTripId,
    getCourierTrips: (state) => (courierId) => {
      return state.trips.filter(trip => trip.fulfilmentUserId === courierId)
    },
    getCourierTripsClone: (_state, getters) => (courierId) => {
      return cloneTrips(getters.getCourierTrips(courierId))
    },
    tripPolyline: state => state.tripPolyline,
    getCachedTrip: (state) => (tripFingerprint) => state.cachedTrips[tripFingerprint]
  }
}
