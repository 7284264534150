export default {
  setLoading({ commit }, value) {
    commit('setLoading', value)
  },
  setErrorMessage({ commit }, { type, value }) {
    commit('setErrorMessage', {
      type,
      value
    })
  },
  clearErrorMessage({ commit }, type) {
    commit('setErrorMessage', {
      type,
      value: null
    })
  },
  setErrorCode({ commit }, { type, value }) {
    commit('setErrorCode', {
      type,
      value
    })
  }
}
