import Vue from 'vue'
import Vuex from 'vuex'

import HTTPService from './store/transport/HTTPService'
import cookies from '@js/cookies'

import { apiManagerMiddleware } from './store/middlewares/api-manager-middleware'

import { api } from './store/services/api'
import { courier } from './store/services/courier'
import { filters } from './store/services/filters'
import { hub } from './store/services/hub'
import { sessionUser } from './store/services/session-user'
import { task } from './store/services/task'
import { timeslot } from './store/services/timeslot'
import { trip } from './store/services/trip'
import { user } from './store/services/user'

Vue.use(Vuex)

const PLATFORM = 'web'
const CLIENT = 'mayo'

HTTPService.setup({
  getPlatform() {
    return PLATFORM
  },
  getClient() {
    return CLIENT
  },
  getAccessToken: () => {
    return cookies.getCookie('external_access_token')
  },
  onUnauthorised: () => {
    return cookies.deleteCookie('external_access_token')
  },
  clearOldSession: () => {
    return cookies.deleteCookie('session_user_uuid')
  },
  getSessionUuid: () => {
    let sessionUserUuid = cookies.getCookie('session_user_uuid')
    if (!sessionUserUuid) {
      const uuid = require('uuid/v4')
      sessionUserUuid = uuid()
      cookies.setCookie('session_user_uuid', sessionUserUuid)
    }
    return sessionUserUuid
  }
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    apiManagerMiddleware
  ],
  modules: {
    api,
    courier,
    filters,
    hub,
    sessionUser,
    task,
    timeslot,
    trip,
    user
  }
})
