import ApiRoutes from './ApiRoutes'
import HTTP from './HTTPService'

export default {
  checkAuth() {
    return HTTP.get(ApiRoutes.checkAuthURL())
  },
  getUserAccess() {
    return HTTP.get(ApiRoutes.userAccessURL())
  },
  login(user) {
    return HTTP.post(ApiRoutes.loginURL(), { user })
  },
  logout() {
    return HTTP.delete(ApiRoutes.logoutURL())
  },
  getProfile() {
    return HTTP.get(ApiRoutes.profileURL())
  }
}
