import UserApi from '@/store/transport/UserApi'
import store from '@/store'

export default {
  resetUserStore({ commit }) {
    commit('resetUserStore')
  },
  getUsersRequest({ state, dispatch }) {
    UserApi.getUsers(state.nonCourierTypes).then(
      (response) => {
        dispatch('getUsersSuccess', response)
      },
      (reason) => {
        dispatch('getUsersFailed', reason)
      }
    )
  },
  getUsersSuccess({ commit }, response) {
    delete response._status
    commit('setUsers', Object.values(response))
  },
  getUsersFailed() {},
  newUserRequest({ dispatch }) {
    UserApi.newUser().then(
      (response) => {
        dispatch('newUserSuccess', response)
      },
      (reason) => {
        dispatch('newUserFailed', reason)
      }
    )
  },
  newUserSuccess({ commit }, response) {
    commit('setCourierTypes', response.courierTypes)
    commit('setNonCourierTypes', response.nonCourierTypes)
    store.commit('hub/setHubsWithUserGroups', response.hubsWithUserGroups)
  },
  newUserFailed() {},
  createUserRequest({ dispatch }, user) {
    UserApi.createUser(user).then(
      (response) => {
        dispatch('createUserSuccess', response)
      },
      (reason) => {
        dispatch('createUserFailed', reason)
      }
    )
  },
  createUserSuccess({ commit }, response) {
    delete response._status
    commit('addUsers', [response])
  },
  createUserFailed() {},
  updateUserRequest({ dispatch }, { userId, user }) {
    UserApi.updateUser(userId, user).then(
      (response) => {
        dispatch('updateUserSuccess', response)
      },
      (reason) => {
        dispatch('updateUserFailed', reason)
      }
    )
  },
  updateUserSuccess({ commit }, response) {
    delete response._status
    commit('updateUsers', [response])
  },
  updateUserFailed() {}
}
