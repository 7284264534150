import TaskApi from '@/store/transport/TaskApi'
import store from '@/store'

export default {
  resetTaskStore({ commit }) {
    commit('resetTaskStore')
  },
  setHighlightedTask({ commit }, value) {
    commit('setHighlightedTask', value)
  },
  getIncomingTasksRequest({ dispatch }, data) {
    TaskApi.getIncomingTasks(data).then(
      (response) => {
        dispatch('getIncomingTasksSuccess', response)
      },
      (reason) => {
        dispatch('getIncomingTasksFailed', reason)
      }
    )
  },
  getIncomingTasksSuccess({ commit }, response) {
    commit('setTasks', response.tasks)
    commit('setGroupedTaskIds', response.groupedTaskIds)
  },
  getIncomingTasksFailed() {},
  getTasksRequest({ dispatch }, data) {
    TaskApi.getTasks(data).then(
      (response) => {
        dispatch('getTasksSuccess', response)
      },
      (reason) => {
        dispatch('getTasksFailed', reason)
      }
    )
  },
  getTasksSuccess({ commit }, response) {
    commit('setTasks', response.tasks)
    commit('setGroupedTaskIds', response.groupedTaskIds)
  },
  getTasksFailed() {},
  searchTasksRequest({ dispatch }, data) {
    TaskApi.searchTasks(data).then(
      (response) => {
        dispatch('searchTasksSuccess', response)
      },
      (reason) => {
        dispatch('searchTasksFailed', reason)
      }
    )
  },
  searchTasksSuccess({ commit }, response) {
    // response is an object with keys 0, 1, 2, etc. and _status
    delete response._status
    commit('setSearchTasks', Object.values(response))
  },
  searchTasksFailed() {},
  markTasksAsLateRequest({ dispatch }, data) {
    TaskApi.markTasksAsLate(data).then(
      (response) => {
        dispatch('markTasksAsLateSuccess', response)
      },
      (reason) => {
        dispatch('markTasksAsLateFailed', reason)
      }
    )
  },
  markTasksAsLateSuccess({ dispatch }, response) {
    delete response._status
    dispatch('updateTasks', Object.values(response))
  },
  markTasksAsLateFailed() {},
  markTasksAsFailedRequest({ dispatch }, data) {
    TaskApi.markTasksAsFailed(data).then(
      (response) => {
        dispatch('markTasksAsFailedSuccess', response)
      },
      (reason) => {
        dispatch('markTasksAsFailedFailed', reason)
      }
    )
  },
  markTasksAsFailedSuccess({ dispatch }, response) {
    delete response._status
    dispatch('updateTasks', Object.values(response))
  },
  markTasksAsFailedFailed() {},
  markTasksAsCompletedRequest({ dispatch }, data) {
    TaskApi.markTasksAsCompleted(data).then(
      (response) => {
        dispatch('markTasksAsCompletedSuccess', response)
      },
      (reason) => {
        dispatch('markTasksAsCompletedFailed', reason)
      }
    )
  },
  markTasksAsCompletedSuccess({ dispatch }, response) {
    delete response._status
    dispatch('updateTasks', Object.values(response))
  },
  markTasksAsCompletedFailed() {},
  getTaskByIdentifierRequest({ dispatch }, identifier) {
    TaskApi.getTaskByIdentifier(identifier).then(
      (response) => {
        dispatch('getTaskByIdentifierSuccess', response)
      },
      (reason) => {
        dispatch('getTaskByIdentifierFailed', reason)
      }
    )
  },
  getTaskByIdentifierSuccess({ commit }, response) {
    delete response._status
    commit('setTask', response)
  },
  getTaskByIdentifierFailed() {},
  unassignTaskRequest({ dispatch }, taskId) {
    TaskApi.unassignTask(taskId).then(
      (response) => {
        dispatch('unassignTaskSuccess', response)
      },
      (reason) => {
        dispatch('unassignTaskFailed', reason)
      }
    )
  },
  unassignTaskSuccess({ commit }, response) {
    delete response._status
    commit('updateTasks', [response])

    // remove task from previous trip
    store.getters['trip/trips'].some(trip => {
      const taskIndex = trip.tasks.findIndex(task => task.id === response.id)
      if (taskIndex !== -1) {
        const tasks = trip.tasks.concat()
        tasks.splice(taskIndex, 1)

        const updatedTrip = {
          ...trip,
          tasks
        }
        store.commit('trip/updateTrips', [updatedTrip])
        return true
      }
      return false
    })
  },
  unassignTaskFailed() {},
  createTaskCommentRequest({ dispatch }, { taskId, comment }) {
    TaskApi.createTaskComment(taskId, comment).then(
      (response) => {
        dispatch('createTaskCommentSuccess', response)
      },
      (reason) => {
        dispatch('createTaskCommentFailed', reason)
      }
    )
  },
  createTaskCommentSuccess({ commit }, response) {
    delete response._status
    commit('setTask', response)
  },
  createTaskCommentFailed() {},
  assignTaskToPickuppRequest({ dispatch }, taskId) {
    TaskApi.assignTaskToPickupp(taskId).then(
      (response) => {
        dispatch('assignTaskToPickuppSuccess', response)
      },
      (reason) => {
        dispatch('assignTaskToPickuppFailed', reason)
      }
    )
  },
  assignTaskToPickuppSuccess({ dispatch }, response) {
    delete response._status
    // remove from left panel
    dispatch('removeTask', response)
  },
  assignTaskToPickuppFailed() {},
  batchAssignTasksToPickuppRequest({ dispatch }, taskIds) {
    TaskApi.batchAssignTasksToPickupp(taskIds).then(
      (response) => {
        dispatch('batchAssignTasksToPickuppSuccess', response)
      },
      (reason) => {
        dispatch('batchAssignTasksToPickuppFailed', reason)
      }
    )
  },
  batchAssignTasksToPickuppSuccess({ dispatch }, response) {
    delete response._status
    // remove from left panel
    console.log('batchAssignTasksToPickuppSuccess', response)
    Object.values(response).forEach(task => {
      dispatch('removeTask', task)
    })
  },
  batchAssignTasksToPickuppFailed() {},
  courierNameOverrideRequest({ dispatch }, payload) {
    const { courierName, orderIdentifiers } = payload
    TaskApi.courierNameOverride(courierName, orderIdentifiers).then(
      (response) => {
        dispatch('courierNameOverrideSuccess', response)
      },
      (reason) => {
        dispatch('courierNameOverrideFailed', reason)
      }
    )
  },
  courierNameOverrideSuccess() {},
  courierNameOverrideFailed() {},
  reassignTaskToPickuppRequest({ dispatch }, taskId) {
    TaskApi.reassignTaskToPickupp(taskId).then(
      (response) => {
        dispatch('reassignTaskToPickuppSuccess', response)
      },
      (reason) => {
        dispatch('reassignTaskToPickuppFailed', reason)
      }
    )
  },
  reassignTaskToPickuppSuccess({ dispatch }, response) {
    delete response._status
    // remove from left panel
    dispatch('removeTask', response)

    // remove trip if it only contains target task
    const trips = store.getters['trip/trips']
    trips.some(trip => {
      if (trip.tasks.some(task => task.id === response.id)) {
        if (trip.tasks.length === 1) {
          store.commit('trip/removeTrip', trip.id)
        }
        return true
      }
      return false
    })
  },
  reassignTaskToPickuppFailed() {},
  setTaskOrderIdentifier({ commit }, value) {
    commit('setTaskOrderIdentifier', value)
  },
  setReassigningTask({ commit }, value) {
    commit('setReassigningTask', value)
  },
  addTask({ commit }, value) {
    commit('addTask', value)
  },
  removeTask({ commit }, value) {
    commit('removeTask', value)
  },
  updateTasks({ commit }, value) {
    commit('updateTasks', value)

    if (value.length > 0) {
      // update trips that include the updated tasks
      const trips = store.getters['trip/trips']
      if (trips.length > 0) {
        const updatedTrips = []
        value.forEach(updatedTask => {
          const updatedTrip = getUpdatedTrip(trips, updatedTask)
          if (updatedTrip) {
            updatedTrips.push(updatedTrip)
          }
        })
        if (updatedTrips.length > 0) {
          store.commit('trip/updateTrips', updatedTrips)
        }
      }

      // update trip if it includes updated tasks
      const trip = store.getters['trip/trip']
      if (trip) {
        const tasks = trip.tasks.concat()
        value.forEach(updatedTask => {
          const taskIndex = tasks.findIndex(tripTask => tripTask.id === updatedTask.id)
          if (taskIndex !== -1) {
            tasks[taskIndex] = updatedTask
          }
        })
        store.commit('trip/setTrip', {
          ...trip,
          tasks
        })
      }
    }
  }
}

function getUpdatedTrip(trips, updatedTask) {
  let updatedTrip
  // break loop once trip containing task is found
  trips.some(trip => {
    if (trip.tasks) {
      // replace with updatedTask in trip
      const taskIndex = trip.tasks.findIndex(tripTask => tripTask.id === updatedTask.id)
      if (taskIndex !== -1) {
        const tasks = trip.tasks.concat()
        tasks[taskIndex] = updatedTask
        updatedTrip = {
          ...trip,
          tasks
        }
        return true
      }
    }
    return false
  })
  return updatedTrip
}
