import Vue from 'vue'

Vue.mixin({
  data() {
    return {
      errorNotification: null,
      defaultNotificationOptions: {
        position: 'is-top'
      },
      defaultToastOptions: {
        position: 'is-top'
      }
    }
  },
  methods: {
    removeApiListeners(requestName, callback) {
      this.$eventBus.$off(`${requestName}Success`, callback)
      this.$eventBus.$off(`${requestName}Failed`, callback)
    },
    addApiListeners(requestName, callback) {
      this.removeApiListeners(requestName, callback)

      this.$eventBus.$on(`${requestName}Success`, callback)
      this.$eventBus.$on(`${requestName}Failed`, callback)
    },
    openErrorNotification(options) {
      this.closeErrorNotification()
      this.errorNotification = this.$buefy.notification.open({
        ...this.defaultNotificationOptions,
        indefinite: true,
        type: 'is-danger',
        ...options
      })
      return this.errorNotification
    },
    closeErrorNotification() {
      if (this.errorNotification) {
        this.errorNotification.close()
      }
    },
    watchErrorNotification(watchProperty) {
      this.$watch(watchProperty, (newVal) => {
        if (newVal) {
          this.openErrorNotification({
            message: newVal
          })
        } else {
          this.closeErrorNotification()
        }
      })
    },
    openToast(options) {
      return this.$buefy.toast.open({
        ...this.defaultToastOptions,
        ...options
      })
    },
    watchLoadingOnce(watchProperty, propertyName) {
      // $watch() returns an unwatch handler
      const unwatchProperty = this.$watch(watchProperty, (newVal) => {
        if (!newVal) {
          this[propertyName] = true
          unwatchProperty()
        }
      })
    }
  }
})
