export default {
  getCookie(cookieName) {
    const name = cookieName + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const cookies = decodedCookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i]
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1)
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length)
      }
    }
    return ''
  },
  setCookie(cookieName, value, expiresAt = null) {
    let expiry = ''
    if (expiresAt !== null) {
      expiry = ';expires=' + new Date(expiresAt).toGMTString()
    }
    document.cookie = cookieName + '=' + value + ';path=/' + expiry
  },
  deleteCookie(cookieName) {
    document.cookie = cookieName + '=' + ';path=/' + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
}
