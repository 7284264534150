import Vue from 'vue'
import actions from './user-actions'

function getInitialState() {
  return {
    users: [],
    courierTypes: [],
    nonCourierTypes: []
  }
}

export const user = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetUserStore(state) {
      const initialState = getInitialState()
      for (var key in state) {
        state[key] = initialState[key]
      }
    },
    setUsers(state, value) {
      state.users = value
    },
    setCourierTypes(state, value) {
      state.courierTypes = value
    },
    setNonCourierTypes(state, value) {
      state.nonCourierTypes = value
    },
    addUsers(state, value) {
      value.forEach(newUser => {
        state.users.push(newUser)
      })
    },
    updateUsers(state, value) {
      value.forEach(updatedUser => {
        const userIndex = state.users.findIndex(user => user.id === updatedUser.id)
        if (userIndex !== -1) {
          Vue.set(state.users, userIndex, updatedUser)
        }
      })
    }
  },

  actions,

  getters: {
    users: state => state.users,
    courierTypes: state => state.courierTypes,
    nonCourierTypes: state => state.nonCourierTypes
  }
}
