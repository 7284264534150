import { camelizeKeys, decamelizeKeys } from 'humps'
import first from 'lodash/first'
import mapValues from 'lodash/mapValues'
import size from 'lodash/size'

const MAXIMUM_RETRIES = 3
const BASE_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

const retryPromise = (operation, retries, shouldRetry) => {
  if (retries <= 1) return operation()
  return operation().catch((reason) => {
    if (shouldRetry(reason)) { return retryPromise(operation, retries - 1, shouldRetry) }
    return Promise.reject(reason)
  })
}

const HTTPService = {
  _getMaximumRetries() { return MAXIMUM_RETRIES },
  _getBaseHeader() { return BASE_HEADER },
  _getPlatform() { },
  _getClient() { },
  _onUnauthorised() { },
  _clearOldSession() { },
  _getAccessToken() { },
  _getSessionUuid() { },

  _forceLogout(response) {
    HTTPService._onUnauthorised()
    return response.json().then((responseJSON) => {
      const camelCaseJSON = camelizeKeys(responseJSON)

      // Clear session uuid if user has logged out elsewhere
      if (camelCaseJSON.code === 401003) HTTPService._clearOldSession()

      return Promise.reject(camelCaseJSON)
    })
  },

  _getRequestHeaders() {
    const accessToken = HTTPService._getAccessToken()
    const sessionUuid = HTTPService._getSessionUuid()

    return {
      ...HTTPService._getBaseHeader(),
      ...(accessToken ? { Authorization: `Token token=${accessToken}` } : {}),
      'X-Platform': HTTPService._getPlatform(),
      'X-Client': HTTPService._getClient(),
      sessionUuid
    }
  },

  _fetch(...args) {
    return fetch(...args)
  },

  _fetchRetry(...args) {
    return retryPromise(
      () => HTTPService._fetch(...args),
      HTTPService._getMaximumRetries(),
      (reason) => reason.message === 'Network request failed'
    )
  },

  _request(url, method, body) {
    const fetchParams = {
      method,
      headers: HTTPService._getRequestHeaders()
    }

    if (body) {
      const snakeCaseJSON = decamelizeKeys(body)
      fetchParams.body = JSON.stringify(snakeCaseJSON)
    }

    return HTTPService._fetchRetry(url, fetchParams)
      .then((response) => {
        if (response.status === 401) return HTTPService._forceLogout(response)
        return response
          .json()
          .catch((error) => {
            const errorMetadata = {
              url,
              method,
              status: response.status,
              request: JSON.stringify(body),
              response: JSON.stringify(response)
            }
            // eslint-disable-next-line no-console
            console.log(error, errorMetadata)
            /**
             *  TODO: Log error to sentry with error and errorMetadata
             *  TODO: Move error message to en.json
             */
            return Promise.reject(new Error('Error Message'))
          })
          .then((responseJSON) => {
            const camelCaseJSON = camelizeKeys(responseJSON)
            if (response.status >= 200 && response.status < 400) {
              return {
                _status: response.status,
                ...camelCaseJSON
              }
            }
            return Promise.reject(camelCaseJSON)
          })
      })
      .catch((reason) => {
        if (reason.errors) { return Promise.reject(mapValues(reason.errors, first(reason.errors))) }
        if (reason.error) { return Promise.reject(reason.error) }
        return Promise.reject(reason)
      })
  },

  setup({
    getMaximumRetries,
    getBaseHeader,
    getPlatform,
    getClient,
    onUnauthorised,
    clearOldSession,
    getAccessToken,
    getSessionUuid
  }) {
    if (getMaximumRetries) HTTPService._getMaximumRetries = getMaximumRetries
    if (getBaseHeader) HTTPService._getBaseHeader = getBaseHeader
    HTTPService._getPlatform = getPlatform
    HTTPService._getClient = getClient
    HTTPService._onUnauthorised = onUnauthorised
    HTTPService._clearOldSession = clearOldSession
    HTTPService._getAccessToken = getAccessToken
    HTTPService._getSessionUuid = getSessionUuid
  },

  post(url, params) {
    return HTTPService._request(url, 'POST', params)
  },

  patch(url, params) {
    return HTTPService._request(url, 'PATCH', params)
  },

  delete(url, params) {
    return HTTPService._request(url, 'DELETE', params)
  },

  _getQueryString(queryParams) {
    if (size(queryParams) === 0) return ''
    const decamelized = decamelizeKeys(queryParams)
    return `?${Object.keys(decamelized).map((key) => `${key}=${decamelized[key]}`).join('&')}`
  },

  get(url, queryParams) {
    const queryString = HTTPService._getQueryString(queryParams)
    return HTTPService._request(url + queryString, 'GET')
  }
}

/**
 *  TODO: Wrap HTTPService with error handler
 */

export default HTTPService
