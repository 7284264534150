import Vue from 'vue'
import actions from './courier-actions'

function getInitialState() {
  return {
    couriers: [],
    groupedCourierIds: {},
    selectedCourier: null
  }
}

export const courier = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetCourierStore(state) {
      const initialState = getInitialState()
      for (var key in state) {
        state[key] = initialState[key]
      }
    },
    setCouriers(state, value) {
      state.couriers = value
    },
    setGroupedCourierIds(state, value) {
      state.groupedCourierIds = value
    },
    setSelectedCourier(state, value) {
      state.selectedCourier = value
    },
    addCouriers(state, value) {
      value.forEach(newCourier => {
        state.couriers.push(newCourier)
      })
    },
    updateCouriers(state, value) {
      value.forEach(updatedCourier => {
        const courierIndex = state.couriers.findIndex(courier => courier.id === updatedCourier.id)
        if (courierIndex !== -1) {
          Vue.set(state.couriers, courierIndex, updatedCourier)
        }
      })
    }
  },

  actions,

  getters: {
    couriers: state => state.couriers,
    courierGroups: state => {
      return Object.keys(state.groupedCourierIds).map(groupName => {
        const courierIds = state.groupedCourierIds[groupName]
        return {
          name: formatCourierGroupName(groupName),
          couriers: state.couriers.filter(courier => courierIds.includes(courier.id)),
          hubShortCode: getHubName(groupName),
          isThirdParty: groupName.includes('3P')
        }
      }).sort(sortCourierGroups)
    },
    selectedCourier: state => state.selectedCourier
  }
}

function getHubName(name) {
  return name.split('Hub')[0].toUpperCase()
}

function formatCourierGroupName(name) {
  const [prefix, suffix] = name.split('Hub')
  return [prefix.toUpperCase(), 'Hub', suffix.replace('3P', '3P ')].join(' ')
}

function sortCourierGroups(a, b) {
  // sort by hubs, with 3P at the back
  if (a.hubShortCode < b.hubShortCode) {
    return -1
  } else if (a.hubShortCode > b.hubShortCode) {
    return 1
  } else {
    if (!a.isThirdParty && b.isThirdParty) {
      return -1
    } else if (a.isThirdParty && b.isThirdParty) {
      return 1
    }
  }
  return 0
}
