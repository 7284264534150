export default {
  resetFiltersStore({ commit }) {
    commit('resetFiltersStore')
  },
  setSelectedDate({ commit }, value) {
    commit('setSelectedDate', value)
  },
  setSelectedTimeRange({ commit }, value) {
    commit('setSelectedTimeRange', value)
  },
  setSelectedHubIds({ commit }, value) {
    commit('setSelectedHubIds', value)
  }
}
