import actions from './timeslot-actions'

function getInitialState() {
  return {
    timeslots: []
  }
}

export const timeslot = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetTimeslotStore(state) {
      const initialState = getInitialState()
      for (var key in state) {
        state[key] = initialState[key]
      }
    },
    setTimeslots(state, value) {
      state.timeslots = value
    }
  },

  actions,

  getters: {
    timeslots: state => {
      return Object.keys(state.timeslots).map((key, i) => {
        return {
          id: i + 1,
          startTime: parseInt(key),
          label: state.timeslots[key]
        }
      })
    },
    getTimeslotIdByStartTime: (_state, getters) => (startTime) => {
      const selectedTimeslot = getters.timeslots.find(timeslot => startTime === timeslot.startTime)
      if (selectedTimeslot) {
        return selectedTimeslot.id
      }
      return null
    }
  }
}
